import React from 'react'
import styled from 'styled-components'
import { MainLayout} from "../styles/Layouts"
import Title from "../Components/Title"
import ImageSection from '../Components/ImageSection'
import ServicesSection from '../Components/ServicesSection'
import ReviewsSection from '../Components/ReviewsSection'
function AboutPage() {
    return (
        <MainLayout>
            <AboutStyled>
                <Title title={'About Me'} span={'About Me'} />
                <ImageSection />
                <ServicesSection />
                <ReviewsSection/>
            </AboutStyled>
        </MainLayout>  
    )
}

const AboutStyled = styled.div`
    margin-right: 0
`;
export default AboutPage
