import React from 'react';
import styled from 'styled-components';
import {InnerLayout} from '../styles/Layouts';
import ServiceCard from './ServiceCard';
import Title from './Title';
import blogger from '../img/blogger.svg'
import intelligence from '../img/intelligence.svg'
import webDevelopment from '../img/web-development.svg'

function ServicesSection() {
    return (
        <InnerLayout>
            <ServicesSectionStyled>
                <Title title={"Services"} span={"Services"} />
                <div className="services">
                    <ServiceCard
                        image={webDevelopment}
                        title={"Web Development"}
                        paragraph={'I am full stack web developer and have years of experience in working with node, react, next, three and other web technologies.'}
                    />
                    <div className="mid-card">
                        <ServiceCard
                            image={intelligence}
                            title={"Artifical intelligence"}
                            paragraph={`I have done Specialization and Data Analytics and since then I also developed an Interest in AI and Deep Learning. I also have Udacity nano-degree in Deep Learning.`}
                        />
                    </div>
                    <ServiceCard
                        image={blogger}
                        title={"Software Development"}
                        paragraph={`I started making softwares for different platforms since I was a kid. I've also created SDKs and CLI tools for my company.`}
                    />
                </div>
            </ServicesSectionStyled>
        </InnerLayout>
    )
}

const ServicesSectionStyled = styled.section`
    .services{
        margin-top: 5rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.5rem;
        @media screen and (max-width: 1000px){
            flex-direction: column;
        }
        @media screen and (max-width: 950px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: 650px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
`;

export default ServicesSection
