import React from 'react'
import styled from 'styled-components'

function useNotification() {
    const [message, setMessage] = React.useState(null);
        const [messageType, setMessageType] = React.useState('info');
        const DELAY = 5000;
      
        React.useEffect(() => {
          if (!message) { return; }
      
          const timer = window.setTimeout(() => setMessage(null), DELAY);
      
          return () => {
            window.clearTimeout(timer);
          };
        }, [message]);
      
        function addNotification(notification, type = 'info') {
          setMessage(notification);
          setMessageType(type);
        }
      
        function Notification() {
            return (
              <UseNotificationStyled>
                    <div className={`notification notification-${messageType}`} role="alert">
                        {message && <div className="notification-content">{message}</div>}
                    </div>
              </UseNotificationStyled>
          );
        }
      
        return [Notification, addNotification];
}

const UseNotificationStyled = styled.div`
    .notification {
    animation: fadein 0.3s ease-in-out;
    background-color: #c8d8ff;
    border-radius: 4px;
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.07);
    color: rgba(0, 0, 0, 0.75);
    text-align: center;
    }

    .notification-error {
    background-color: #f9c5c5;
    }

    .notification-success {
    background-color: #d9f59f;
    }

    .notification-content {
    padding: 1rem;
    }

    @keyframes fadein {
    from {
        opacity: 0;
        transform: translateY(-20%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
    }
`;

export default useNotification
