import React from 'react'
import styled from 'styled-components'
import {InnerLayout} from '../styles/Layouts'
import Title from './Title'
import SmallTitle from './SmallTitle'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import SchoolIcon from '@material-ui/icons/School'
import ResumeItem from './ResumeItem'
function Resume() {
    const briefcase = <BusinessCenterIcon />
    const school = <SchoolIcon/> 
    return (
        <SkilledStyled>
            <Title title={'Resume'} span={'Resume'}/>
            <InnerLayout>
                <div className="small-title">
                    <SmallTitle icon={briefcase} title={'Working Experience'} />    
                </div>
                <div className="resume-content">
                    <ResumeItem
                        year={'2021 - Present'}
                        title={'Software Engineer-III'}
                        subtitle={'Walmart Global Tech Private Limited'}
                        text={`Working as full-time Software Engineer-III at Walmart Global Tech Private Limited.`}
                    />
                    <ResumeItem
                        year={'2020 - 2021'}
                        title={'Full stack Developer'}
                        subtitle={'Botmock'}
                        text={`It was a startup startup started in 2017. I worked there in different staks and languages including nodejs, react, laravel, typescript and others.`}
                    />
                    <ResumeItem
                        year={'2021 - 2021'}
                        title={'Googel Summer of Code mentor'}
                        subtitle={'GSoC Mentor in R Organisation'}
                        text={`I become mentor in R Organisation where I help and guide a student in an Open-Source Project.`}
                    />
                    <ResumeItem
                        year={'2020 - 2020'}
                        title={'Googel Summer of Code student'}
                        subtitle={'GSoC Student in R Organisation'}
                        text={`In 2020, again I was selected as a student in R Organisation where my goal was to develop a production-ready, state-of-the-art biodiversity data dashboard.`}
                    />
                    <ResumeItem
                        year={'2019 - 2020'}
                        title={'Alexa Student Influencer'}
                        subtitle={'Become one of the 10 student from India'}
                        text={`In 2020 I become ALexa Student influencer. Only 10 student from whole India was selected.`}
                    />
                    

                    <div className="u-margin-bottom">
                        <ResumeItem
                            year={'2019 - 2019'}
                            title={'Googel Summer of Code student'}
                            subtitle={'GSoC Student in R Organisation'}
                            text={`In 2020, I was selected as a student in R Organisation. I developed an interactive shiny dashboard that allows users to visualize different aspects of biodiversity data such as temporal, taxonomic and spatial without worrying about coding.`}
                        />
                    </div>
                </div>
                <div className="small-title">
                    <SmallTitle icon={school} title={'Education'} />
                </div>
                <div className="resume-content">
                    <ResumeItem
                        year={'2017 - 2021'}
                        title={'Computer Science Degree'}
                        subtitle={'Galgotias University'}
                    />
                    <div className="u-margin-bottom">
                        <ResumeItem
                            year={'2016 - 2017'}
                            title={'Intermediate'}
                            subtitle={'Alpine Public School'}
                        />
                        <ResumeItem
                            year={'2014 - 2015'}
                            title={'High School'}
                            subtitle={'Alpine Public School'}
                        />
                    </div>
                </div>
            </InnerLayout>
        </SkilledStyled>
    )
}

const SkilledStyled = styled.section`
    .small-title{
        padding-bottom: 3rem;
    }

    .resume-content{
        border-left: 2px solid var(--border-color);
    }
`;

export default Resume
