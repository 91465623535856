import React, {useState} from 'react'
import {MainLayout, InnerLayout} from '../styles/Layouts'
import Title from '../Components/Title'
import portfolios from '../data/portfolios'
import Menu from '../Components/Menu'
import Button from '../Components/Button'

const allButtons = ['All', ...new Set(portfolios.map(item => item.category))]

function PortfoliosPage() {
    const [menuItems, setMenuItems] = useState(portfolios);
    const filter = (button) => {
        if (button === 'All') {
            setMenuItems(portfolios)
            return;
        }
        const filteredData = portfolios.filter(item => item.category === button);
        setMenuItems(filteredData)
    }

    return (
        <MainLayout>
            <Title title={'Portfolios'} span={'Portfolios'} />
            <InnerLayout>
                <Button filter={filter} buttons={allButtons}/>
                <Menu menuItem={menuItems}/>
            </InnerLayout>
        </MainLayout>
    )
}

export default PortfoliosPage
