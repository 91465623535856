import blog1 from '../img/blogs/blog1.jpg';
import blog2 from '../img/blogs/blog2.jpg';
import blog3 from '../img/blogs/blog3.jpg';
import blog4 from '../img/blogs/blog4.jpg';
import blog5 from '../img/blogs/blog5.jpg';
import blog6 from '../img/blogs/blog6.jpg';
import blog7 from '../img/blogs/blog7.jpg';
import blog8 from '../img/blogs/blog8.jpg';
import blog9 from '../img/blogs/blog9.jpg';
import blog10 from '../img/blogs/blog10.jpg';
import blog11 from '../img/blogs/blog11.jpg';
import blog12 from '../img/blogs/blog12.jpg';

const blogs = [
    {
        id: 1,
        title: 'How to Enable the Right Click on Websites That Disable It',
        date: '21',
        month: 'July',
        image: blog1,
        url: 'https://techtalks-wm.blogspot.com/2016/07/how-to-enable-right-click-on-websites.html'

    },
    {
        id: 2,
        title: 'How To Create Key Logger Using Notepad',
        date: '21',
        month: 'July',
        image: blog2,
        url: 'https://techtalks-wm.blogspot.com/2016/07/how-to-create-key-logger-using-notepad.html'


    },
    {
        id: 3,
        title: 'Steps Make Your Own Popular Blog Using These Best Simple Tricks',
        date: '7',
        month: 'July',
        image: blog3,
        url: 'https://techtalks-wm.blogspot.com/2016/06/steps-make-your-own-popular-blog-using.html'

    },
    {
        id: 4,
        title: 'How To Install and Run Backtrack On Android',
        date: '21',
        month: 'July',
        image: blog4,
        url: 'https://techtalks-wm.blogspot.com/2016/07/how-to-enable-right-click-on-websites.html'
    },
    {
        id: 5,
        title: 'How To Change your SIM number Using Android',
        date: '21',
        month: 'July',
        image: blog5,
        url: 'https://techtalks-wm.blogspot.com/2016/06/how-to-change-your-sim-number-using.html'
    },
    {
        id: 6,
        title: 'How To Install Xposed Framework In Android',
        date: '21',
        month: 'July',
        image: blog6,
        url: 'https://techtalks-wm.blogspot.com/2016/06/how-to-install-xposed-framework-in.html'
    },
    {
        id: 7,
        title: 'How To Reset Your Forgotten Windows 10 Login Password Using CMD',
        date: '21',
        month: 'July',
        image: blog7,
        url: 'https://techtalks-wm.blogspot.com/2016/06/how-to-reset-your-forgotten-windows-10.html'
    },
    {
        id: 8,
        title: 'How to Unlock VLC’s hidden Jigsaw Puzzle Mode',
        date: '21',
        month: 'July',
        image: blog8,
        url: 'https://techtalks-wm.blogspot.com/2016/06/how-to-unlock-vlcs-hidden-jigsaw-puzzle.html'
    },
    {
        id: 9,
        title: 'How To Make A Password Protected Folder That Hides Itself All Using Notepad',
        date: '21',
        month: 'July',
        image: blog9,
        url: 'https://techtalks-wm.blogspot.com/2016/05/how-to-make-password-protected-folder.html'
    },
    {
        id: 10,
        title: 'How to Hide Any Drive in Windows Using Command Prompt',
        date: '21',
        month: 'July',
        image: blog10,
        url: 'https://techtalks-wm.blogspot.com/2016/05/how-to-hide-any-drive-in-windows-using.html'
    },
    {
        id: 11,
        title: 'How to Remove Bloatware (Pre-Installed Apps) from Android Device(with or without root)',
        date: '21',
        month: 'July',
        image: blog11,
        url: 'https://techtalks-wm.blogspot.com/2016/05/how-to-remove-bloatware-pre-installed.html'
    },
    {
        id: 12,
        title: 'How make make a bootable pendrive to install windows os (using cmd)',
        date: '21',
        month: 'July',
        image: blog12,
        url: 'https://techtalks-wm.blogspot.com/2016/04/how-make-make-bootable-pendrive-to.html'
    }
]

export default blogs