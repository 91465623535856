import React from 'react'
import styled from 'styled-components';
import resume from '../img/resume.jpeg';
// import PrimaryButton from './PrimaryButton';

function ImageSection() {
    return (
        <ImageSectionStyled>
            <div className="left-content">
                <img src={resume} alt="resume"/>
            </div>
            <div className="right-content">
                <h4>I am <span>Rahul Chauhan</span></h4>
                <p className="paragraph">
                    Hi! I am Rahul. I am curre a full-stack Software Developer at Botmock. I like learning and working on new technologies. I also love teaching and mentoring others.
                </p>
                <div className="about-info">
                    <div className="info-title">
                        <p>Full Name</p>
                        <p>Age</p>
                        <p>Nationality</p>
                        <p>Languages</p>
                        <p>Location</p>
                        <p>Service</p>
                    </div>
                    <div className="info">
                        <p>: Rahul Chauhan</p>
                        <p>: 21</p>
                        <p>: Indian</p>
                        <p>: English / Hindi</p>
                        <p>: India</p>
                        <p>: Freelencer</p>
                    </div>
                </div>
                {/* <PrimaryButton title={'Download CV'}/> */}
            </div>
        </ImageSectionStyled>
    )
}

const ImageSectionStyled = styled.div`
    margin-top: 5rem;
    display: flex;
    @media screen and (max-width: 1000px){
        flex-direction: column;
        .left-content{
            margin-bottom: 2rem;
        }
    }

    .left-content {
        width: 100%;
        img{
            width: 90%;
            height: 100%;
            object-fit: cover;
        }
    }
    .right-content {
        h4{
            font-size: 2rem;
            color: var(--white-color);
            span{
                font-size: 2rem;
            }
        }
        .paragraph{
            padding: 2rem 0;
        }
        .about-info {
            display: flex;
            .info-title{
                padding-right: 3rem;
                p{
                    font-weight: 600;
                }
            }
            .info-title, .info {
                p{
                    padding: .4rem 0;
                }
            }
        }
    }
`;


export default ImageSection
