import React from 'react'
import styled from 'styled-components'
import {InnerLayout} from '../styles/Layouts'
import ReviewItem from './ReviewItem'
import Title from './Title'

function ReviewsSection() {
    return (
        <ReviewStyled>
            <Title title={'Endorses'} span={"Endorses"} />
            <InnerLayout>
                <div className="reviews">
                    <ReviewItem text={`Worked with Rahul for more than 2+ years for the Technical Club of College TechnoJam, Over these years he has shown a great set of skills and enthusiasm form helping, teaching and mentoring students to unlocking achievements.
Learning and working on new technologies has always been his plus point. I can say he is a teammate and mentor anyone will be lucky to have.
All the best, Rahul`} />
                    <ReviewItem text={"I have worked with Rahul during the past year as one of his GSoC mentors. Rahul was a great student: driven, creative and easy to work with. He was able to enhance his skillset significantly during a very short time frame. Moreover, Rahul keeps on contributing to the bdverse project (https://bdverse.org/) long after his GSoC project has ended, and I consider him a valuable team member (https://bdverse.org/Team/). I have no hesitation in recommending Rahul and believe he will be a great asset in your organization"}/>
                </div>
            </InnerLayout>
        </ReviewStyled>
    )
}

const ReviewStyled = styled.section`
    .reviews{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
        width: 100%;
        @media screen and (max-width: 650px){
            grid-template-columns: repeat(1, 1fr);
        }
    }   
`;

export default ReviewsSection
