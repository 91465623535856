import { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "./Components/Sidebar";
import HomePage from "./Pages/HomePage";
import {Switch as Switching, Route} from "react-router";
import AboutPage from "./Pages/AboutPage"
import BlogsPage from "./Pages/BlogsPage"
import ContactPage from "./Pages/ContactPage"
import PortfoliosPage from "./Pages/PortfoliosPage"
import ResumePage from "./Pages/ResumePage"
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Switch from "@material-ui/core/Switch";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton } from "@material-ui/core";

function App() {
  const [theme, setTheme] = useState('dark-theme');
  const [checked, setChecked] = useState(false);
  const [sideBarClickActive, setSideBarClickActive] = useState(false);
  const [navToggle, setNavToggle] = useState(false);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme, navToggle])


  const themToggler = () => {
    if (theme === 'light-theme') {
      setTheme('dark-theme');
      setChecked(false);
    } else {
      setTheme('light-theme');
      setChecked(true);
    }
  }

  return (
    <div className="App">
      <Sidebar navToggle={navToggle} setNavToggle={setNavToggle} sideBarClickActive={sideBarClickActive} setSideBarClickActive={setSideBarClickActive}/>
      <div className="theme">
        <div className="light-dark-mode">
            <div className="left-content">
              <Brightness4Icon/>
            </div>
            <div className="right-content">
              <Switch
              value=""
              checked={checked}
              inputProps={{ 'aria-label': '' }}
              size="medium"
              onClick={themToggler}
              />
            </div>
        </div>
      </div>

      <div className="ham-burger-menu">
        <IconButton onClick={() => {
          setNavToggle(true)
          setSideBarClickActive(true)
        }}>
          <MenuIcon/>
        </IconButton>
      </div>
      
      <MainContentStyled>
        <div className="lines">
          <div className="line-1"></div>
          <div className="line-2"></div>
          <div className="line-3"></div>
          <div className="line-4"></div>
        </div>
        <div className="coffee">
          <a href="https://www.buymeacoffee.com/calledRahul">
            <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" />
          </a>
        </div>

        <Switching>
          <Route path="/" exact >
            <HomePage/>
          </Route>
          <Route path="/about" exact>
            <AboutPage/>
          </Route>
          <Route path="/resume" exact>
            <ResumePage/>
          </Route>
          <Route path="/portfolios" exact>
            <PortfoliosPage/>
          </Route>
          <Route path="/blogs" exact>
            <BlogsPage/>
          </Route>
          <Route path="/contact" exact>
            <ContactPage/>
          </Route>
        </Switching>
      </MainContentStyled>
      
   </div>
  );
}

const MainContentStyled = styled.main`
  position: relative;
  margin-left: 16.3rem;
  min-height:100%;
  transition: all 0.4s ease-in-out;
  @media screen and (max-width:1200px){
    margin-left: 0px;
  }

  .lines{
    z-index: -1;
    position: absolute;
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .line-1, .line-2, .line-3, .line-4{
      width: 1px;
      min-height: 100%;
      background-color: var(--border-color);
    }
  }
  .coffee{
    position: fixed;
    right: 1%;
    top: 47%;
    width: 6.5rem;
    height: 94vh;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      display: flex;
      align-items: center;
      width: 7.4rem;
    }
    a{
      display: flex;
      align-items: center;
      width: 8rem;
    }
    /* position: absolute;
    margin-left: 90%;
    margin-top: 94vh;
    img{
      width: 8rem;
    } */

  }
`;

export default App;
