import img1 from '../img/portImages/img1.png';
import img2 from '../img/portImages/img2.png';
import img3 from '../img/portImages/img3.png';
import img4 from '../img/portImages/img4.png';
import img5 from '../img/portImages/img5.png';
import img6 from '../img/portImages/img6.png';
import img7 from '../img/portImages/img7.png';
import img8 from '../img/portImages/img8.png';
import img9 from '../img/portImages/img9.png';
import img10 from '../img/portImages/img10.png';

const portfolios = [
    {
        id: 1,
        category: 'React',
        image: img1,
        link1: 'https://github.com/rahulchauhan049/react-3d-eath-threejs',
        title: '3D Earth Using React and ThreeJS',
        text: 'I made a 3D Earth using React and ThreeJS. This is an experiment to learn fundamentals of ThreeJS.'
    },
    {
        id: 2,
        category: 'R',
        image: img2,
        link1: 'https://github.com/rahulchauhan049/covidDashboard',
        title: 'Covid Dashboard',
        text: 'A Shiny Dashboard used for covid-19 data trend visualizations.'
    },
    {
        id: 3,
        category: 'React',
        image: img3,
        link1: 'https://github.com/rahulchauhan049/netflix-clone-react',
        title: 'Netflix Clone',
        text: 'A fun experience where I used React to create a netflix like UI.'
    },
    {
        id: 4,
        category: 'CSS',
        image: img4,
        link1: 'https://github.com/rahulchauhan049/colorGame-css-js',
        title: 'CSS Color Game',
        text: 'A simple game made in CSS where user has to guess the right color based on RGB value.'
    },
    {
        id: 5,
        category: 'R',
        image: img5,
        link1: 'https://github.com/rahulchauhan049/colorGame-css-js',
        title: 'bdDashboard',
        text: 'A Reactive and Interactive Dashboard which allows user to visualize different aspects of biodiversity data. Also contains modules that will help user to add new pages using single line of code.'
    },
    {
        id: 6,
        category: 'React',
        image: img6,
        link1: 'https://github.com/The-Virtual-Dancers/Virtual_Dancers_Messenger',
        title: 'React Messenger',
        text: 'A Messenger made using React and firebase real-time database.'
    },
    {
        id: 7,
        category: 'React',
        image: img7,
        link1: 'https://github.com/rahulchauhan049/devBook-A-Social-Networking-Website-For-Developers',
        title: 'DevBook',
        text: 'A Social Networking website for Developers'
    },
    {
        id: 8,
        category: 'Python',
        image: img8,
        link1: 'https://github.com/rahulchauhan049/galgotias_icloud_feedback_bot',
        title: 'Galgotias ICloud feedback bot',
        text: 'A script which fill 10+ page feedback form of Galgotias University hosted on Icloud.'
    },
    {
        id: 9,
        category: 'Python',
        image: img9,
        link1: 'https://github.com/rahulchauhan049/youtube_automatic_title_changer_script/blob/master/index.py',
        title: 'YouTube Automatic Title Changer',
        text: `A Python script that'll automatically rename the youtube video title based on no. of views on that video.`
    },
    {
        id: 10,
        category: 'NodeJS',
        image: img10,
        link1: 'https://github.com/rahulchauhan049/TJ-ISB-TechnoJam-Identity-Scanner-Backend/blob/master/routes/api.js',
        title: 'TechnoJam Identity Scanner',
        text: `A fun project. It's login user after scanning a unique barcode on their ID card and a secret key.`
    }
]

export default portfolios