import React from 'react';
import { InnerLayout, MainLayout } from '../styles/Layouts';
import styled from 'styled-components';
import Title from '../Components/Title';
import PrimaryButton from '../Components/PrimaryButton';
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ContactItem from '../Components/ContactItem';
import emailjs from 'emailjs-com';
import useNotification from '../Components/Notification';


function ContactPage() {
    const phone = <PhoneIcon />
    const email = <EmailIcon />
    const location = <LocationOnIcon />

    const [Notification, addNotification] = useNotification();


    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_cj7nykc', 'template_9jt8iqt', e.target, 'user_JJBuHjMgjHEWGzEtakIyH')
          .then((result) => {
              addNotification(`Thanks for your message! I'll Revert back to soon.😀`, 'success');
            }, (error) => {
              addNotification('Something went wrong. Try again!', 'error');
          });
        e.target.reset();
    }
    
    return (
        <MainLayout>
            <Title title={'Contact'} span={'Contact'} />
            <ContactStyled>
                <InnerLayout className="contact-section">
                    <div className="left-content">
                        <div className="contact-title">
                            <h4>Get in Touch</h4>
                        </div>
                        <form className="form" onSubmit={sendEmail}>
                            <div className="form-field">
                                <label htmlFor="name" id="name">Enter your name*</label>
                                <input id="name" type="text" name="name" required/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="email" id="email">Enter your email*</label>
                                <input id="email" type="email" name="email" required/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="subject" id="subject">Enter your subject*</label>
                                <input id="subject" type="text" name="subject" required/>
                            </div>
                            <div className="form-field">
                                <label htmlFor="text-area"> Enter your Message*</label>
                                <textarea name="textarea" id="textarea" cols="30" rows="10" required></textarea>
                            </div>
                            <div className="form-field form-button">
                                <PrimaryButton title={"Send Email"} />
                            </div>
                            <Notification/>
                        </form>
                    </div>
                    <div className="right-content">
                        <ContactItem icon={phone} title={"Phone"} tel={'+918475974537'} />
                        <ContactItem icon={email} title={"Email"} email={'rahul.chauhan049@gmail.com'} />
                        <ContactItem icon={location} title={"Address"} address={'Greater Noida, Uttar Pradesh, India'}/>
                    </div>
                </InnerLayout>
            </ContactStyled>
            
        </MainLayout>
    )
}

const ContactStyled = styled.div`
    .contact-section{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 2rem;

        @media screen and (max-width: 978px){
            grid-template-columns: repeat(1, 1fr);
            .form-button{
                margin-bottom: 3rem;
            }
        }
        .right-content{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
        .contact-title{
            h4{
                color: var(--white-color);
                padding: 1rem 0;
                font-size: 1.8rem;
            }
        }
        .form{
            width: 100%;
            @media screen and (max-width: 502px){
                width: 100%;
            }
            .form-field{
                margin-top: 2rem;
                position: relative;
                width: 100%;
                label{
                    position: absolute;
                    left: 20px;
                    top: -19px;
                    display: inline-block;
                    background-color: var(--background-dark-color);
                    padding: 0 .5rem;
                    color: inherit;
                }
                input{
                    border: 1px solid var(--border-color);
                    outline: none;
                    background: transparent;
                    height: 50px;
                    padding: 0 15px;
                    width: 100%;
                    color: inherit;
                }
                textarea{
                    background: transparent;
                    border: 1px solid var(--border-color);
                    outline: none;
                    color: inherit;
                    width: 100%;
                    padding: .8rem 1rem;
                }
            }

            .form-button{
                margin-bottom: 2rem;
            }
        }
    }
`;
export default ContactPage
