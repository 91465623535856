import React from 'react'
import styled from 'styled-components'
import {InnerLayout} from '../styles/Layouts'
import Title from './Title'
// import ProgressBar from './ProgressBar'
const skills = [`nodejs`, `React`, `Angular`, `Shiny`, `dplyr`, `purrr`, `golem`, `ggplot`, `plotly`, `leaflet`, `d3`, `lodash`, `lazy`, `bootstrap`, `git`, `numpy`, `pandas`, `scikit`, `tensorflow`, `keras`, `pytorch`, 'ThreeJS']
function Skills() {
    return (
        <SkilledStyled>
            <Title title={'My Skills'} span={'My Skills'} />
                <InnerLayout>
                <div className="skills">
                    {skills.map((skill, i) => (<h6 id={i}>{skill}</h6>))}
                        {/* <ProgressBar title={'HTML5'} width={'80%'} text={'80%'}/>
                        <ProgressBar title={'CSS3'} width={'30%'} text={'30%'}/>
                        <ProgressBar title={'JAVASCRIPT'} width={'85%'} text={'85%'}/>
                        <ProgressBar title={'R'} width={'60%'} text={'60%'}/>
                        <ProgressBar title={'PYTHON'} width={'70%'} text={'70%'}/>
                        <ProgressBar title={'REACT'} width={'75%'} text={'75%'}/>  */}
                    </div>
                </InnerLayout>
        </SkilledStyled>
    )
}

const SkilledStyled = styled.section`
    .skills{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-row-gap: 2rem;
        grid-column-gap: 2rem;
        @media screen and (max-width: 700px){
            grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (max-width: 570px){
            grid-template-columns: repeat(3, 1fr);
        }
    }
`;

export default Skills
