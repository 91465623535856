import React from "react";
import styled from "styled-components";
import TwitterIcon from "@material-ui/icons/Twitter";
import GithubIcon from "@material-ui/icons/GitHub";
import LinkedinIcon from "@material-ui/icons/LinkedIn";
import Particle from "../Components/Particles";

function HomePage() {
  return (
    <HomePageStyled>
      <div className="particle-con">
        <Particle />
      </div>
      <div className="typography">
        <h1>
          Hi, I'm <span>Rahul</span>
        </h1>
        <p>
          🐸 B.Tech Engineer in CS, SDE@Botmock, GSoC Mentor 2021, GSoC Student 2019-20, Alexa Student Influencer 2019, self-taught Programmer, Love to contribute in
          Open-Source.
        </p>
        <div className="icons">
          <a href="https://twitter.com/calledRahul" className="icon i-twitter">
            <TwitterIcon />
          </a>
          <a
            href="https://github.com/rahulchauhan049/"
            className="icon i-github"
          >
            <GithubIcon />
          </a>
          <a
            href="https://www.linkedin.com/in/rahul-chauhan049/"
            className="icon i-linkedin"
          >
            <LinkedinIcon />
          </a>
        </div>
        {/* <p className="workInProgress">
          This website is still work in progress. Do visit again 🐸
        </p> */}
      </div>
    </HomePageStyled>
  );
}

const HomePageStyled = styled.header`
  width: 100%;
  height: 100%;
  position: relative;
  .p-particles-js {
    position: absolute;
    top: 0;
    left: 0;
  }

  .typography {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%auto;

    .icons {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      .icon {
        border: 2px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        &:hover {
          border: 2px solid var(--primary-color);
          color: var(--primary-color);
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
        svg {
          margin: 0.5rem;
        }
      }

      .i-linkedin {
        &:hover {
          border: 2px solid #0e76a8;
          color: #0e76a8;
        }
      }

      .i-github {
        &:hover {
          border: 2px solid #5f4687;
          color: #5f4687;
        }
      }

      .i-twitter {
        &:hover {
          border: 2px solid #00acee;
          color: #00acee;
        }
      }
    }
  }
`;

export default HomePage;
