import React from 'react'
import styled from 'styled-components';

function ContactItem({icon, title, tel=null, email=null, address=null}) {
    return (
        <ContactItemStyled>
            <div className="left-content">
                <p>
                    {icon}
                </p>
            </div>
            <div className="right-content">
                    <h6>{title}</h6>
                    {tel ? <a className="contact" href={`tel://${tel}`}>{tel}</a> : null}
                    {email ? <a className="contact" href={`mailto:${email}`}>{email}</a> : null}
                    {address ? <p className="contact">{address}</p> : null}
            </div>
        </ContactItemStyled>
    )
}

const ContactItemStyled = styled.div`
    padding: 1.5rem 2rem;
    background-color: var(--background-dark-grey);
    display: flex;
    align-items: center;
    @media screen and (max-width:502px){
        padding: 1.5rem 0.3rem;
    }  
    &:not(:last-child){
        margin-bottom: 2.5rem;
    }
    .left-content{
        padding: 1.5rem;
        border: 1px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.5rem;
        svg{
            font-size: 2.3rem;
        }
        @media screen and (max-width:502px){
            svg{
            font-size: 1.5rem;
        }
        }
    }
    .right-content{
        @media screen and (max-width:502px){
            a, p{
                font-size: .8rem;
            }
        }
        h6{
            color: var(--white-color);
            font-size: 1.2rem;
            padding-bottom: .6rem;
        }
        p{
            padding: .1rem 0;
            
        }
    }
`;

export default ContactItem
